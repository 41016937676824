import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import KPIBubbleChart from "../../components/WelcomePage/KPIBubbleChart";
import { useHistory, useLocation } from "react-router-dom";
import { notification } from "antd";
import { UNLOGGED } from "util/constants";
import { apiRequest } from "util/services";
import { useDispatch, useSelector } from "react-redux";
import { themeColor } from "util/common";
import { Layout, Row, Col, Card, Button, Tooltip } from "antd";
import KPILineChart from "../../components/WelcomePage/KPILineChart";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";

import {
  FileDoneOutlined,
  DownloadOutlined,
  ThunderboltOutlined,
  DashboardOutlined,
  TeamOutlined,
  ArrowRightOutlined,
  InfoCircleOutlined,
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TikTokOutlined,
  CommentOutlined,
  YoutubeOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  AlertOutlined,
} from "@ant-design/icons";

const { Content } = Layout;

const WelcomePage = ({ match, shared, authenticated, user }) => {
  const history = useHistory();
  const [teamName, setTeamName] = useState([]);
  const [openRecomm, setOpenRecomm] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [kpiData, setKpiData] = useState([]);
  const [loadingKpi, setLoadingKpis] = useState(true);
  const [emergentTopic, setEmergentTopic] = useState([]);
  const [loadingEmergent, setLoadingEmergent] = useState(true);
  const [countDashboard, setCountDashboard] = useState(0);
  const [alertsCount, setAlertsCount] = useState([]);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();

  const handleClick = (e) => {
    if (e.currentTarget.dataset.key == "learning_center") {
      const win = window.open("https://learning.pivony.com/", "_blank");
      win.focus();
    }
    else if (e.currentTarget.dataset.key == "knowledge_center") {
      const win = window.open(
        "https://pivony.notion.site/Pivony-Knowledge-Center-59a7a883a48349a6975bf47576edc407",
        "_blank"
      );
      win.focus();
    }
    else if (e.currentTarget.dataset.key == "blogs") {
      const win = window.open("https://www.pivony.com/blogs", "_blank");
      win.focus();
    }
    else if (e.currentTarget.dataset.key == "contact") {
      const win = window.open("mailto:support@pivony.com", "_blank");
      win.focus();
    }
    else {
      const win = window.open("https://pivony.notion.site/AskPivony-1af51223ae3080fcadd3ef50ecd098a0", "_blank");
      win.focus();
    }

  };
  useEffect(() => {
    if (authenticated === UNLOGGED) {
      history.push("/");
    }
    setLoading(true);
    const getUserTeam = async () => {
      try {
        const result = await apiRequest("users/team", "POST");
        if (result?.success) {
          setTeamName(result?.success);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getUserTeam();
    //get subscription details

    const getSubscriptionData = async () => {
      try {
        const result = await apiRequest("welcome/subscription-details", "GET");
        if (result) {
          setSubscriptionData(result);
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: "Subscription Data Not Available",
        });
      }
    };
    getSubscriptionData();
    const getHomeData = async () => {
      try {
        const result = await apiRequest("welcome/most-recent-report", "POST", {
          sort: "newest",
        });
        if (result) {
          setReportData(result);
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: "Report Data Not Available",
        });
      }
    };
    getHomeData();
    const getKPIData = async () => {
      try {
        const result = await apiRequest("welcome/kpi-data", "GET");
        if (result) {
          setKpiData(result);
          setLoadingKpis(false);
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: "KPI Data Not Available",
        });
        setLoadingKpis(false);
      }
    };
    getKPIData();
    const getEmergentTopic = async () => {
      try {
        const result = await apiRequest("welcome/emergent-topic", "GET");
        if (result) {
          setEmergentTopic(result?.topics[0]);
          setLoadingEmergent(false);
        }
      } catch (err) {
        console.log(err);
        setLoadingEmergent(false);
        notification.error({
          message: "Top Topic Not Available",
        });
      }
    };
    getEmergentTopic();
    setLoading(false);
  }, []);
  const kpiDataDummy = {
    topIncreasingKpi: { name: "Sales Growth", value: "+20%" },
    topDecreasingKpi: { name: "Customer Churn", value: "-5%" },
    topEmergentTopics: ["Topic 1", "Topic 2", "Topic 3"],
  };
  const handleReportDownload = (link) => {
    if (link) {
      window.open(link, "_blank");
    } else {
      notification.error({
        message: "Error",
      });
    }
  };
  const ReportItem = ({ reportName, csvLink }) => (
    <div className={styles.tableRow}>
      <p className={styles.nameRow}>{reportName}</p>
      <p className={styles.moreRow}>
        <div
          onClick={() => handleReportDownload(csvLink)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadOutlined />
        </div>
      </p>
    </div>
  );
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content className={styles.mainContent}>
        <div className={styles.introSection}>
          <div className={styles.top}>
            <div className={styles.title}>
              <div className={styles.text}>
                <h5>{t("welcome.1")} 🚀</h5>
              </div>
            </div>
            {teamName ? (
              <div className={styles.teamBtn}>
                {" "}
                <TeamOutlined /> {teamName}{" "}
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className={styles.introCTA}>
            <div>
              <Trans
                i18nKey="welcome.2"
                t={t}
                tOptions={{ interpolation: { escapeValue: false } }}
              />
            </div>
            <div className={styles.ctaGroupButton}>
              <Row gutter={16}>
                <Button
                  className={styles.ctaButton}
                  icon={<FileDoneOutlined />}
                  onClick={() => history.push("/console/report")}
                >
                  {t("welcome.3")}
                </Button>
                <Button
                  className={styles.ctaButton}
                  icon={<DashboardOutlined />}
                  onClick={() => history.push("/console/global_executive")}
                >
                  {t("welcome.4")}
                </Button>
                <Button
                  className={styles.ctaButton}
                  icon={<ThunderboltOutlined />}
                  onClick={() => history.push("/console/industryTopics")}
                >
                  {t("welcome.5")}
                </Button>
              </Row>
            </div>
          </div>
        </div>
        {(Object.keys(alertsCount) != 0 && alertsCount?.count == 0) ||
        countDashboard == 0 ? (
          <>
            <div className={styles.cardsTitle}>
              {openRecomm ? <>{t("welcome.6")} </> : ""}
              <div
                className={styles.hideOption}
                onClick={() => {
                  setOpenRecomm(!openRecomm);
                }}
              >
                {openRecomm ? (
                  <>
                    <>{t("welcome.7")} </>
                  </>
                ) : (
                  <div style={{ marginBottom: "20px" }}>{t("welcome.35")}</div>
                )}
              </div>
            </div>
            {openRecomm ? (
              <Row gutter={[16, 24]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Card
                    title={
                      <span className={styles.titleContent}>
                        {" "}
                        {t("welcome.8")}{" "}
                      </span>
                    }
                    bordered={false}
                    className={styles.welcomeCard}
                  >
                    <div className={styles.icon3}>
                      <AlertOutlined className={styles.iconImg} />
                    </div>
                    <ul>{t("welcome.9")}</ul>
                    <ul>
                      <Button
                        onClick={() => {
                          history.push("console/alerts");
                        }}
                        className={styles.ctaButton}
                      >
                        {t("welcome.10")}
                      </Button>
                    </ul>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  {" "}
                  {/* Adjusted column span to 12 */}
                  <Card
                    title={
                      <span className={styles.titleContent}>
                        {t("welcome.11")}{" "}
                      </span>
                    }
                    bordered={false}
                    className={styles.welcomeCard}
                  >
                    <div className={styles.icon3}>
                      <FacebookOutlined className={styles.iconImg} />
                      <InstagramOutlined className={styles.iconImg} />
                      <LinkedinOutlined className={styles.iconImg} />
                      <TikTokOutlined className={styles.iconImg} />
                    </div>
                    <ul>{t("welcome.12")}</ul>
                    <ul>
                      <Button
                        onClick={() => {
                          history.push("settings/integrations");
                        }}
                        className={styles.ctaButton}
                      >
                        {t("welcome.13")}
                      </Button>
                    </ul>
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </>
        ) : null}
        <div className={styles.cardsTitleWithInfo}>
          {t("welcome.14")}
          <Tooltip placement="bottom" title={t("welcome.15")}>
            <InfoCircleOutlined className={styles.infoIcon} />
          </Tooltip>
        </div>
        {/* KPI Cards Section */}
        <Row gutter={[16, 24]}>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Card
              title={
                <span className={styles.titleContent}>{t("welcome.16")}</span>
              }
              bordered={false}
              className={styles.welcomeCard}
              extra={
                <a
                  className={styles.navigationLink}
                  href={`${window.location.origin +
                    "/console/global_executive"}`}
                >
                  <ArrowRightOutlined />
                </a>
              }
              loading={loadingKpi}
            >
              <div className={styles.kpiMainContainer}>
                {kpiData?.top_increasing && Object.keys(kpiData)?.length !== 0 &&
                Object.keys(kpiData?.top_increasing !== 0) ? (
                  <>
                    <div className={styles.titleContent}>
                      {kpiData?.top_increasing?.name}
                    </div>

                    <div>
                      {kpiData?.top_increasing?.percentage_change ==
                      "No change" ? (
                        <div
                          style={{
                            fontSize: "18px",
                            color: theme === "dark" ? "white" : "black",
                            marginTop: "5px",
                          }}
                        >
                          {t("welcome.36")}
                        </div>
                      ) : (
                        <div
                          style={{
                            color: "#0A8F0E",
                            fontSize: "18px",
                            fontWeight: "500",
                            marginTop: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "14px",
                            }}
                          >
                            {kpiData?.top_increasing?.percentage_change}%
                          </div>
                        </div>
                      )}

                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight: "300",
                        }}
                      >
                        {t("welcome.37")}
                        <span style={{ marginRight: "4px" }}>
                          {" "}
                          {t("welcome.38")}
                        </span>
                      </div>
                      <div className={styles.kpiChartContainer}>
                        <KPILineChart data={kpiData?.top_increasing} />
                      </div>
                    </div>
                  </>
                ) : (
                  <div>{t("welcome.17")}</div>
                )}
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Card
              title={
                <span className={styles.titleContent}>{t("welcome.18")}</span>
              }
              extra={
                <a
                  className={styles.navigationLink}
                  href={`${window.location.origin +
                    "/console/global_executive"}`}
                >
                  <ArrowRightOutlined />
                </a>
              }
              bordered={false}
              className={styles.welcomeCard}
              loading={loadingKpi}
            >
              {kpiData?.top_decreasing && Object.keys(kpiData)?.length !== 0 &&
              Object.keys(kpiData?.top_decreasing !== 0) ? (
                <div>
                  <div className={styles.titleContent}>
                    {" "}
                    {kpiData?.top_decreasing?.name}{" "}
                  </div>
                  {kpiData?.top_decreasing?.percentage_change == "No change" ? (
                    <div
                      style={{
                        fontSize: "18px",
                        color: theme === "dark" ? "white" : "black",
                        marginTop: "5px",
                      }}
                    >
                      {t("welcome.36")}{" "}
                    </div>
                  ) : (
                    <div
                      style={{
                        color: "#C94445",
                        fontSize: "18px",
                        fontWeight: "500",
                        marginTop: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "14px",
                        }}
                      >
                        -{kpiData?.top_decreasing?.percentage_change}%
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "300",
                    }}
                  >
                    {t("welcome.37")}
                    <span style={{ marginRight: "4px" }}>
                      {" "}
                      {t("welcome.38")}
                    </span>
                  </div>
                  <div className={styles.kpiChartContainer}>
                    <KPILineChart data={kpiData?.top_decreasing} />
                  </div>
                </div>
              ) : (
                <div>
                  {t("welcome.17")}
                </div>
              )}
            </Card>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Card
              title={
                <span className={styles.titleContent}>{t("welcome.19")}</span>
              }
              extra={
                <a
                  className={styles.navigationLink}
                  href={`${window.location.origin +
                    "/console/global_executive"}`}
                >
                  <ArrowRightOutlined />
                </a>
              }
              bordered={false}
              className={styles.welcomeCard}
              loading={loading}
            >
              <div>
                {emergentTopic ? (
                  <KPIBubbleChart data={emergentTopic} />
                ) : (
                  <div>{t("welcome.39")}</div>
                )}
              </div>
            </Card>
          </Col>
        </Row>

        {/* Subscription Information */}
        <div className={styles.cardsTitleWithInfo}>
          {t("welcome.20")}
          <Tooltip placement="bottom" title={t("welcome.21")}>
            <InfoCircleOutlined className={styles.infoIcon} />
          </Tooltip>
        </div>
        <Row gutter={[16, 24]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card
              title={
                <span className={styles.titleContent}>{t("welcome.22")}</span>
              }
              className={styles.welcomeCard}
              bordered={false}
              loading={loading}
            >
              <div>
                <p>
                  <strong>{t("welcome.23")}:</strong>{" "}
                  {subscriptionData?.PackageName}
                </p>
                <p>
                  <strong>{t("welcome.24")}:</strong>{" "}
                  {subscriptionData?.RemainingTopicBoards}
                </p>
                <p>
                  <strong>{t("welcome.25")}:</strong>{" "}
                  {moment(subscriptionData?.EndDate).format("ll")}
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            {/* Report Section */}

            <Card
              title={
                <span className={styles.titleContent}>{t("welcome.25")}</span>
              }
              className={styles.welcomeCard}
              bordered={false}
              extra={
                <a
                  className={styles.navigationLink}
                  href={`${window.location.origin + "/console/report"}`}
                >
                  <ArrowRightOutlined />
                </a>
              }
              loading={loading}
            >
              <div className={styles.tableContainer}>
                {Object.keys(reportData)?.length !== 0 ? (
                  <div>
                    {reportData.map((item) => (
                      <div>
                        <ReportItem
                          reportName={item.Name}
                          csvLink={item.ReportLink}
                        />
                        <div className={styles.rowSep}></div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>{t("welcome.40")}</div>
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "18px",
                  }}
                ></div>
              </div>
            </Card>
          </Col>
        </Row>

        <div className={styles.cardsTitleWithInfo}>
          {" "}
          {t("welcome.26")}
          <Tooltip placement="bottom" title={t("welcome.27")}>
            <InfoCircleOutlined className={styles.infoIcon} />
          </Tooltip>
        </div>
        <div className={styles.cardsSubTitle}>{t("welcome.28")}</div>

        <Row gutter={[16, 24]}>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <Card
              className={styles.supportCard}
              title={
                <span className={styles.titleContent}>{t("welcome.41")}</span>
              }
              hoverable
              data-key="learning_center"
              onClick={handleClick}
              bordered={false}
              cover={
                <div className={styles.coverImg}>
                  <YoutubeOutlined style={{ fontSize: "30px" }} />
                </div>
              }
            >
              {t("welcome.29")}
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <Card
              className={styles.supportCard}
              title={
                <span className={styles.titleContent}>{t("welcome.42")}</span>
              }
              cover={
                <div className={styles.coverImg}>
                  <QuestionCircleOutlined style={{ fontSize: "30px" }} />
                </div>
              }
              data-key="knowledge_center"
              onClick={handleClick}
              hoverable
              bordered={false}
            >
              {t("welcome.30")}
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <Card
              className={styles.supportCard}
              title={
                <span className={styles.titleContent}>{t("welcome.43")}</span>
              }
              cover={
                <div className={styles.coverImg}>
                  <ReadOutlined style={{ fontSize: "30px" }} />
                </div>
              }
              data-key="blogs"
              onClick={handleClick}
              hoverable
              bordered={false}
            >
              {t("welcome.31")}
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <Card
              className={styles.supportCard}
              title={
                <span className={styles.titleContent}>{t("welcome.44")}</span>
              }
              hoverable
              cover={
                <div className={styles.coverImg}>
                  <CommentOutlined style={{ fontSize: "30px" }} />
                </div>
              }
              bordered={false}
              onClick={handleClick}
            >
              {t("welcome.32")}
            </Card>
          </Col>
        </Row>
        <div className={styles.cardsSubTitle}>
          <Trans
            i18nKey="welcome.33"
            t={t}
            tOptions={{ interpolation: { escapeValue: false } }}
          />
        </div>
        <div className={styles.ctaGroupButton}>
          <Button
            className={styles.ctaButton}
            data-key="contact"
            onClick={handleClick}
          >
            {t("welcome.34")}
          </Button>
        </div>
      </Content>
    </Layout>
  );
};

export default WelcomePage;
