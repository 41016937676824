import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Tag } from "antd"; // Ensure you use proper components
import styles from "./style.module.scss";
import {
  CommentOutlined,
  CloseOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { apiRequest } from "util/services";

const ChatBot = () => {
  const { t } = useTranslation();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([
    { text: t("chat.1"), sender: "bot" },
  ]);
  const [inputValue, setInputValue] = useState("");
  const [botTyping, setBotTyping] = useState(false);
  const [disableinput, setDisableInput] = useState(false);
  const messagesEndRef = useRef(null);
  

  const handleSendMessage = async (message = null) => {
    const text = message ?? inputValue; // Ensure text is assigned properly
    if (!text || typeof text !== "string" || text.trim() === "") return; // Prevent errors

    const userMessage = { text: text, sender: "user" };
    setMessages((prev) => [...prev, userMessage]);
    setInputValue(""); // Clear input field
    setBotTyping(true); // Show typing indicator
    setDisableInput(true);

    try {
    
      const result = await apiRequest("dashboards/gpt_answer", "POST",{"text":text});
      if (result?.success) {
        setBotTyping(false);
        simulateTypingEffect(result?.success);
      } else {
        setBotTyping(false);
        simulateTypingEffect(
            t("chat.2")
        );
      }
    } catch (error) {
      setBotTyping(false);
      console.error("Error fetching bot response:", error);
      simulateTypingEffect(t("chat.3"));
    }
  };

  // Handle Enter key submission
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const formatResponse = (response) => {
    return response
      .replace(/#\s*(.+)/g, "<h3>$1</h3>") // Markdown-like headers
      .replace(/\*\*(.+?)\*\*/g, "<strong>$1</strong>") // Bold text
      .replace(/(?:^|\n)\*\s(.+)/g, "<li>$1</li>") // Bullet points
      .replace(/(<li>.*<\/li>)(?!<\/ul>)/g, "<ul>$1</ul>") // Lists
      .replace(/\n/g, "<br/>"); // Line breaks
  };

  const simulateTypingEffect = (response) => {
    const formattedResponse = formatResponse(response);
    let index = 0;

    const interval = setInterval(() => {
      setMessages((prev) => {
        const lastMessage = prev[prev.length - 1];

        if (lastMessage?.sender === "bot") {
          // Append next character to the existing bot message
          return [
            ...prev.slice(0, -1),
            {
              ...lastMessage,
              text: lastMessage.text + formattedResponse[index],
            },
          ];
        } else {
          // Start a new bot message
          return [
            ...prev,
            { text: formattedResponse[index], sender: "bot", isHTML: true },
          ];
        }
      });

      index++;

      if (index === formattedResponse.length) {
        clearInterval(interval);
        setTimeout(() => {
          setBotTyping(false);
          setDisableInput(false); // Enable input when typing finishes
        }, 500);
      }
    }, 10); // Adjust speed of typing effect
  };
  // Suggested questions (clickable)
  const suggestions = [
    t("chat.4"),
    t("chat.5"),
    t("chat.6"),
    t("chat.7"),
    t("chat.8"),
  ];

  return (
    <div>
      {/* Conditional rendering based on isChatOpen */}
      {!isChatOpen ? (
        <Button
          onClick={() => setIsChatOpen(true)}
          className={styles.chatButton}
        >
          <img src="/assets/logo192.png" alt="icon"/>
        </Button>
      ) : (
        <div className={styles.chatWindow}>
          {/* Chat Header */}

          <div className={styles.chatHeader}>
            <span>
              {" "}
              <img src="/assets/logo192.png" alt="icon"/>  AskPivony {} <Tag color="magenta">BETA</Tag>
            </span>
            <Button
              className={styles.closeBtn}
              onClick={() => setIsChatOpen(false)}
            >
              <CloseOutlined />
            </Button>
          </div>
          {/* Messages Container */}
          <div className={styles.messagesContainer}>
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`${styles.message} ${
                  msg.sender === "bot" ? styles.bot : styles.user
                }`}
              >
                {msg.isHTML ? (
                  <span
                    className={`${styles.messageText} ${
                      msg.sender === "bot" ? styles.bot : styles.user
                    }`}
                    dangerouslySetInnerHTML={{ __html: msg.text }}
                  />
                ) : (
                  <span
                    className={`${styles.messageText} ${
                      msg.sender === "bot" ? styles.bot : styles.user
                    }`}
                  >
                    {msg.text}
                  </span>
                )}
              </div>
            ))}

            {botTyping && (
              <div className={styles.message}>
                <span className={styles.typingIndicator}>
                  <span className={styles.dot}></span>
                  <span className={styles.dot}></span>
                  <span className={styles.dot}></span>
                  <span className={styles.dot}></span>
                </span>
              </div>
            )}

            {/* Scroll target */}
            <div ref={messagesEndRef}></div>
          </div>
          {/* Suggestions Section */}
          {!disableinput && (
            <div className={styles.suggestionsContainer}>
              {suggestions.map((suggestion, index) => (
                <button
                  key={index}
                  className={styles.suggestionButton}
                  onClick={() => handleSendMessage(suggestion)}
                  disabled={disableinput}
                >
                  {suggestion}
                </button>
              ))}
            </div>
          )}
          {/* Input Container */}
          <div className={styles.inputContainer}>
            <Input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Type your message..."
              className={styles.chatInput}
            />
            <button
              onClick={() => handleSendMessage()}
              className={styles.sendBtn}
              disabled={disableinput}
            >
              <SendOutlined className={styles.img} />
            </button>
          </div>

          {/* Chat Footer */}

          <div className={styles.chatFooter}>
          {t("chat.9")}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBot;
